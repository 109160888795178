import { useState } from "react"
import styled from "styled-components"

import GoogleMapReact from "google-map-react"

import { ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { greyScale, palette, primaryBackground } from "src/ui/colors"
import MapPinIcon from "src/ui/icons/illustrations/map-marker-home.svg"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function AdjustableHomeMap({
  location,
  height,
  markerWidth,
  showTooltip = true,
  showAdjustButton = true,
  onChangeLocation,
}: {
  location?: ILocation
  height?: string
  markerWidth?: string
  showTooltip?: boolean
  showAdjustButton?: boolean
  onChangeLocation?: (location: ILocation) => void
}) {
  const { latitude: initialLat = 0, longitude: initialLng = 0 } = location || {}
  const [adjustEnabled, setAdjustEnabled] = useState(false)
  const [lat, setLat] = useState(initialLat)
  const [lng, setLng] = useState(initialLng)
  // If the initial coords are unset, we will zoom out to world view, and let the user pick
  const initialZoom = !initialLat && !initialLat ? 0 : 17
  const [zoomLevel, setZoomLevel] = useState(initialZoom)

  // https://developers.google.com/maps/documentation/javascript/controls#ControlsOverview
  const mapOptions: GoogleMapReact.MapOptions = {
    mapTypeControl: false,
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    streetViewControl: false,
    disableDoubleClickZoom: false,
  }

  function handleClickAdjust() {
    if (adjustEnabled) {
      onChangeLocation && onChangeLocation({ latitude: lat, longitude: lng })
      setZoomLevel((value) => value - 1)
    } else {
      setZoomLevel((value) => value + 1)
    }
    setAdjustEnabled((value) => !value)
  }

  function setCenterCoordsAndZoom({
    center,
    zoom,
  }: GoogleMapReact.ChangeEventValue) {
    setZoomLevel(zoom)
    setLat(center.lat)
    setLng(center.lng)
    onChangeLocation &&
      onChangeLocation({ latitude: center.lat, longitude: center.lng })
  }

  const { t, langKeys } = useTranslate()

  return (
    <MTooltip
      arrow={false}
      enabled={showTooltip && !adjustEnabled}
      title="Click adjust to change location"
      offset={-100}
      disableFocusListener
    >
      <MapBox height={height}>
        {showAdjustButton && (
          <AdjustButton variant="subtle" onClick={handleClickAdjust}>
            {adjustEnabled ? t(langKeys.save) : t(langKeys.adjust)}
          </AdjustButton>
        )}

        <Marker $adjustEnabled={adjustEnabled} width={markerWidth}>
          <MapPinIcon width="100%" height="100%" />
        </Marker>

        <GoogleMapReact
          options={mapOptions}
          draggable={adjustEnabled}
          bootstrapURLKeys={{
            key: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
          }}
          center={{ lat: initialLat, lng: initialLng }}
          zoom={zoomLevel}
          onChange={setCenterCoordsAndZoom}
          defaultZoom={initialZoom}
          yesIWantToUseGoogleMapApiInternals
        />
      </MapBox>
    </MTooltip>
  )
}

const MapBox = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : "400px")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  overflow: hidden;

  // hide google branding stuff
  & a[href^="http://maps.google.com/maps"]
  {
    display: none !important;
  }

  & a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  & .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  & .gmnoprint div {
    background: none !important;
  }
`

const AdjustButton = styled(MButton)`
  position: absolute;
  background-color: ${primaryBackground};
  top: ${spacing.M};
  right: ${spacing.M};
  z-index: 10;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${greyScale[200]};
  }
`

const Marker = styled.div<{ width?: string; $adjustEnabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(0%, -50%);
  width: ${({ width }) => width ?? "45px"};
  color: ${({ $adjustEnabled }) =>
    $adjustEnabled ? palette.hav : palette.hejmo};
  transition: color 0.5s;
  z-index: 10;
`

export const AdjustableLocationMap = AdjustableHomeMap
